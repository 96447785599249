@font-face {
  font-family: 'CoinbaseDisplay';
  font-weight: 500;
  src: url('vendor/fonts/coinbase-display-medium.woff2') format('woff2'),
       url('vendor/fonts/coinbase-display-medium.woff') format('woff');
}

@font-face {
  font-family: 'CoinbaseDisplay';
  font-weight: 400;
  src: url('vendor/fonts/coinbase-display-regular.woff2') format('woff2'),
       url('vendor/fonts/coinbase-display-regular.woff') format('woff');
}

@font-face {
  font-family: 'CoinbaseSans';
  font-weight: 500;
  src: url('vendor/fonts/coinbase-sans-medium.woff2') format('woff2'),
       url('vendor/fonts/coinbase-sans-medium.woff') format('woff');
}

@font-face {
  font-family: 'CoinbaseSans';
  font-weight: 400;
  src: url('vendor/fonts/coinbase-sans-regular.woff2') format('woff2'),
       url('vendor/fonts/coinbase-sans-regular.woff') format('woff');
}

body {
  margin: 0;
  font-family: 'CoinbaseSans', 'Helvetica Neue', sans-serif;
  touch-action: pan-x pan-y;
  font-weight: 400;
}

* {
  box-sizing: border-box;
}

html, body, #root {
  height: 100%;
  overflow: hidden;
  background-color: #0A0B0D;
}

iframe {
  pointer-events: none;
}